import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"

const IndexPage = () => {
    const root = "/img/download/conference/workshops"

    return (
        <Layout pageTitle="Workshops - Templates">
            <Row>
                <Col>
                    <Link to="/conference/workshops/workshops-index">Back</Link>
                </Col>
            </Row>
            
            <ImageDownload
                root={root}
                name="Workshop Promo"
                filename="workshop-promo-2"
                text="Use this template to anounce your Workshop at the Women's Business Conference You can use or adapt the ready-to-go copy below to write your caption! Remember to tag us and the sponsor (if applicable)"
                squareHref="https://www.canva.com/design/DAFThSs5QUY/o9YvLRlnU94cs1zvs1uTmQ/view?utm_content=DAFThSs5QUY&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFnM1_IycM/pceKFFMOl9qyWtrKoDyfug/view?utm_content=DAFnM1_IycM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/1H8r8KhTSL-vtNaY0j8ka4W88880IcQh7Bhd9rfFH9Kc/edit?usp=sharing"
            />





        </Layout>
    )
}

export default IndexPage
